import './culture.css';

function Culture() {
  return (
    <div id='culture' className="box-culture container">
        <h1 className="culture-h1">企业文化</h1>
        
        <div className="cultures">
            <div className="cuture-partbox">
                <img src="img-100kb/bg-culture-1.jpg" className="culture-image"></img>
                <h1>忠于热爱与信仰</h1>
                <p>心有所信，方能行远。维熠创始团队的标签是“理想主义者与实干家”，敢于做梦，敢于去闯。我们一直走在把愿景落地的路上，用实际行动证明理想和现实可兼得。</p>
            </div>
            <div className="cuture-partbox">
                <img src="img-100kb/bg-culture-2.jpg" className="culture-image"></img>
                <h1>追求真理与卓越</h1>
                <p>维熠的创始人毕业于哈佛大学，Veritas（真理）是哈佛的校训，也是维熠的核心价值观之一。我们希望将其传递给大众，在能量层面上和更多人深度链接、共振。</p>
            </div>
            <div className="cuture-partbox">
                <img src="img-100kb/bg-culture-3.jpg" className="culture-image"></img>
                <h1>保持感恩与谦虚</h1>
                <p>常对周围的人事物怀抱感激之心是维熠提供服务的基础，保持谦虚的态度是行为准则。戒骄戒躁，脚踏实地也仰望星空，拥抱未来的无限可能。</p>
            </div>
        </div>
    </div>
        
  );
}

export default Culture;