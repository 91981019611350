import { useState } from 'react';
import './introduction.css';

function Introduction() {
	const [banners, setBanners] = useState([
		{ title: "新型心理疗愈空间", subtitle: "构建新型数字心理疗愈空间，为「自我封闭」状态的用户打造疗愈元宇宙。", bg: "img-100kb/bg-instruction-1.jpg" },
		{ title: "实体疗愈系产品", subtitle: "自研生产实体疗愈产品，辅助用户进行沉浸式疗愈训练。", bg: "img-100kb/bg-instruction-2.jpg" },
		{ title: "数字疗愈工作坊", subtitle: "结合冥想、艺术、声音疗愈等多种技术，设计不同疗愈主题供用户选择。", bg: "img-100kb/bg-instruction-3.jpg" },
		{ title: "AI驱动式服务平台", subtitle: "提供既即时又可持续发展的心理疗愈服务，促进有社交需求用户之间的交流。", bg: "img-100kb/bg-instruction-4.jpg" }
	]);

  return (
    <section className='container introduction'>
		<div className="section-header">
			<h1 className="title">产品与服务简介</h1>
			<p className="subtitle">维熠搭建了一个数字化心理疗愈服务系统。该系统核心产品的主要功能在于为用户提供AI驱动的即时情绪处理及长期可持续性的“教学—训练—应用”一体化的个性疗愈服务。在品牌发展过程中，维熠会不断扩充产品布局，把线上服务、实体产品、及线下活动相结合，构建以AI心理疗愈服务为中心的产品宇宙，根据不同用户的特点和需求提供多样化产品。</p>
		</div>
		<div className="banners">
			{banners.map(banner => (
			<div className="banner" style={{backgroundImage: `url('${banner.bg}')`}} key={banner.title}>
				<h1 className="title">{banner.title}</h1>
				<p className="subtitle">{banner.subtitle}</p>
			</div>
			))}
		</div>
    </section>
  );
}

export default Introduction;