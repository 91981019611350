import { useState } from 'react';
import './contact.css';

function Contact() {

	const [contacts, setContacts] = useState([
        { title: "邮箱", content: <p className="content">xiaoyuanman2023@126.com</p>, icon: "img-100kb/email.png" },
        { title: "电话", content: <p className="content">193-5721-5869</p>, icon: "img-100kb/cellphone.png" },
        { title: "地址", content: <p className="content">浙江省杭州市西湖区<br/>金蓬街315号</p>, icon: "img-100kb/map.png" }
	]);

  return (
    <section className='container contact'>
        <div className="contact-header">
            <h1 className="title">联系方式</h1>
        </div>
        <div className="contacts">
            {contacts.map(contact => (
            <div className="card" key={contact.title}>
                <div className="icon-wrap">
                    <img src={contact.icon} alt={contact.title} className="icon" />
                </div>
                <text className="title" >{contact.title}</text>
                {contact.content}
            </div>
            ))}
        </div>
    </section>
  );
}

export default Contact;
