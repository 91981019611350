import './mission.css';

function Mission() {
  return (
    <div id='mission' className="box-mission">
        <div className="mission-container">
            <h1>品牌愿景</h1>
            <p>维熠将品牌命名为「小圆满」，是希望帮助用户实现内心的小圆满，能够可以遇事向内求，由内而外地疗愈伤痛。 我们的愿景是 “点亮人心里的光，治愈人心里的伤”，致力于在纷繁世界中为大众搭建一个可以「守护内心净土、找寻内心平静」的场景 。我们不止想要将“治愈”这件事本身传递给更多内心有伤痛的人，更要把“如何治愈”的方法论教授给大众，让更多人能够发自内心地 爱自己、悦纳自己、认可自己，通过挖掘内在潜力，把控住自己的生活。</p>
        </div>
    </div>
        
  );
}

export default Mission;