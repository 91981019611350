import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { NAV_HEIGHT } from '../../data/navbar.js';
import './navbar.css';

const NavBar = forwardRef((props, ref) => {
  
	useEffect(() => {
	  const handleResize = () => {
		setWidth(window.innerWidth);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

	let [ navs, setNavs ] = useState([
		{ name: "公司介绍", id: "about", selected: false },
		{ name: "企业文化", id: "culture", selected: false },
		{ name: "服务简介", id: "introduction", selected: false },
		{ name: "商务合作", id: "cooperate", selected: false }
	]);

	const [ navButtonClicked, setNavButtonClicked ] = useState(false);
	const [ width, setWidth ] = useState(window.innerWidth);

	function selectNav(id) {
		setNavs(navs.map((item) => ({ ...item, selected: item.id == id})));
	}

	// 导航栏跳转至元素顶部
	// const select = (id) => (event) => {
	// 	if (event) {
	// 		const elementY = document.getElementById(id).getBoundingClientRect().y + window.scrollY;
	// 		const elementHeight = document.getElementById(id).offsetHeight;
	// 		const windowHeight = window.innerHeight;
	// 		const scrollToY = elementY + (elementHeight / 2) + (windowHeight / 2)- NAV_HEIGHT;
			
			
	// 		console.log(window.scrollY)
	// 	}
	
	// 	selectNav(id); 
	// }
	
	// 导航栏跳转至元素中心
	const select = (id) => (event) => {
		if (event) {
			event.preventDefault();
			const Eelement =document.getElementById(id);
			// console.log("elementH:", window.scrollY+document.getElementById(id).getBoundingClientRect().y,",windowH:",window.innerHeight,"  ,total:",
			// document.getElementById(id).getBoundingClientRect().y + window.scrollY - NAV_HEIGHT);
			window.scrollTo({
				top: Math.max(document.getElementById(id).getBoundingClientRect().y + window.scrollY - (NAV_HEIGHT/2)- (window.innerHeight / 2) + (document.getElementById(id).offsetHeight / 2)  , 0),
				behavior:'smooth'
			});
			
		}
		selectNav(id); 
	}
	const toggleNavButtonClicked = () => {
		setNavButtonClicked(!navButtonClicked);
	}

	useImperativeHandle(ref, () => ({
		select,
		selectNav
	}));
	

	return (
		<header className='container'>
			<hgroup className='left'>
				<img src="img-100kb/logo-black.png" alt="小圆满logo" className="logo" />
				<div className="name">
					<p className='wy-name-cn'>杭州维熠教育科技有限公司</p>
					<p className='wy-name-en'>Hangzhou Weiyi Education Technology CO., LTD.</p>
				</div>
			</hgroup>
			{width >= 1200 ? (
			<hgroup className='right normal-screen'>
				<nav>
					<ul>
						{navs.map((nav, index) => (
						<li className="nav-item" key={nav.id}>
							<a href={`#${nav.id}`} className={`tag ${nav.selected ? 'selected' : ''}`} onClick={select(nav.id)}>
								<div className="text">{nav.name}</div>
							</a>
						</li>
						))}
					</ul>
				</nav>
			</hgroup>) : <></>}
			{width < 1200 ? (
			<hgroup className='right small-screen'>
				<button className='btn-nav' onClick={toggleNavButtonClicked} />
				{navButtonClicked ? (
				<nav>
					<ul>
						{navs.map((nav, index) => (
						<li className="nav-item" key={nav.id}>
							<a href={`#${nav.id}`} className={`tag ${nav.selected ? 'selected' : ''}`} onClick={select(nav.id)}>
								<div className="text">{nav.name}</div>
							</a>
						</li>
						))}
					</ul>
				</nav>) : <></>}
			</hgroup>) : <></>}
		</header>
	);
})

export default NavBar;