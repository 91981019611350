import { useState } from 'react';
import './cooperate.css';

function Cooperate() {
	const [banners, setBanners] = useState([
		{ title: "新型心理疗愈空间", subtitle: "构建新型数字心理疗愈空间，为「自我封闭」状态的用户打造疗愈元宇宙。", bg: "img-100kb/bg-instruction-1.png" },
		{ title: "实体疗愈系产品123", subtitle: "自研生产实体疗愈产品，辅助用户进行沉浸式疗愈训练。", bg: "img-100kb/bg-instruction-2.png" },
		{ title: "数字疗愈工作坊", subtitle: "结合冥想、艺术、声音疗愈等多种技术，设计不同疗愈主题供用户选择。", bg: "img-100kb/bg-instruction-3.png" },
		{ title: "AI驱动式服务系统", subtitle: "提供既即时又可持续发展的心理疗愈服务，促进有社交需求用户之间的交流。", bg: "img-100kb/bg-instruction-4.png" }
	]);

  return (
    <section id='cooperate' className='container cooperate'>
      <img src="img/silk.png" alt="silk" className="silk" />
      <img src="img/silk-2.png" alt="silk" className="silk-2" />
      <img src="img/qr.jpg" alt="商务合作 QR Code" className="qr" />
      <div className="text">
        <h1 className="title">商务合作</h1>
        <p className="content">如有商务合作需要，欢迎与我们联系。</p>
        <p className="content">您可以发送邮件至我们的邮箱：<br/>xiaoyuanman2023@126.com</p>
        <p className="content">同时您可以扫描左侧二维码，或微信搜索<br/>“InnerRealm_2024”添加“Inner_Realm小助手”微信  ，<br/>和我们详细沟通落地方式。</p>
      </div>
    </section>
  );
}

export default Cooperate;
