import './aboutus.css';

function AboutUs() {
  return (
    <div id='about' className="box-aboutus">
      <div className="container">
        <div className="text-container">
          <div className="subcontainer">
            <div className="aboutus">
              ABOUT US
            </div>
            <h1>关于我们</h1>
            <p className="about-p">维熠教育科技有限公司（杭州）为研究型应用创新公司，把智能驱动型的AI技术应用于心理疗愈领域。维熠布局在「泛心理」服务市场，专注于为当代「心理亚健康」状态及健康人群提供自助及半自助式的数字化心理疗愈服务。</p>
            <p className="about-p">随着后疫情时代的到来，几乎人人都压力山大，当代年轻人的压力已经从身体上的疲劳转变为了心理上的压力。外在压力来源多种多样，这个时候需要大众看向内在世界的中心——任外在世界如何飘摇，也可以在自己的世界里如如不动。维熠由此出发，旨在打造普适化的自我成长心理疗愈品牌，致力于把“全民养心”的理念普及到千家万户，帮助用户挖掘内在力量、实现可持续性自我成长。在降低真人心理咨询成本的同时，探索以技术为主、人力为辅的创新心理健康服务工具。</p>
          </div>
        </div>
        <img className='logo-white' src="img-100kb/logo-white.png" alt="logo" />
      </div>
    </div>
  );
}

export default AboutUs;