import './home.css';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import AboutUs from '../../components/aboutus/aboutus';
import Culture from '../../components/culture/culture';
import Mission from '../../components/mission/mission';
import Introduction from '../../components/introduction/introduction';
import Cooperate from '../../components/cooperate/cooperate';
import Contact from '../../components/contact/contact';
import { useState, useRef, useEffect } from 'react';
import { NAV_HEIGHT } from '../../data/navbar.js';

function Main() {
  let timeoutId;
  let anchorYs = [0, 700, 1956, 2637];
  const navBarRef = useRef(null);

  useEffect(() => {
    function updateAnchorYs() {
      anchorYs = ['about', 'culture', 'mission', 'introduction', 'cooperate'].map(id => ({id, y: document.getElementById(id).getBoundingClientRect().y + window.scrollY - NAV_HEIGHT}));
      anchorYs[0].y = 0;
    }

    function getSectionID() {
      for (let { id, y } of anchorYs) {
        if (window.scrollY - y >= 0 && window.scrollY - y < document.getElementById(id).offsetHeight){
          return id;
        }
      }
      return undefined;
    }

    function updateNavBar() {
      const id = getSectionID();
      id && navBarRef.current.selectNav(id);
    }

    // // 吸附效果（1/3）
    // function handleScroll() {
    //   timeoutId && clearTimeout(timeoutId);
    //   timeoutId = setTimeout(() => {
    //     // for (let i = 0; i < anchorYs.length; i++) {
    //     for (let { id, y } of anchorYs) {
    //       if (Math.abs(window.scrollY - y) < 246) {    
    //         window.scrollTo({
    //           top: y,
    //           behavior: 'smooth'
    //         });
    //         navBarRef.current && navBarRef.current.select(id)();
    //         break;
    //       }
    //     }
    //   }, 100);
    // };

    updateAnchorYs();
    updateNavBar();

    // // 吸附效果（2/3）
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', updateNavBar);
    window.addEventListener('resize', updateAnchorYs);

    return () => {
      // // 吸附效果（3/3）
      // window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', updateNavBar);
      window.removeEventListener('resize', updateAnchorYs);
    };
  })

  return (
    <div>
      <div className='header-wrap'>
        <NavBar ref={navBarRef} />
        
      </div>
      <AboutUs />
      <Culture />
      <Mission />
      <div id='introduction' className="wrap introduction-wrap">
        <Introduction />
      </div>
      <div className="wrap cooperate-wrap">
        <Cooperate />
      </div>
      <div className="wrap contact-wrap">
        <Contact />
      </div>
      <div className="wrap footer-wrap">
        <Footer />
      </div>
      
    </div>
  );
}

export default Main; 