import './footer.css';

function Footer() {
  return (
    <footer>
        <img src="img-100kb/logo-color.png" alt="小圆满logo" className="logo" />
        <p className="url">www.xiaoyuanman.com</p>
        <p className="copyright">©2023 Xiaoyuanman 版權所有</p>
    </footer>
  );
}

export default Footer;